import { dataLoader } from '../../data-loader';

const endpointLocal = 'rindegastos/expenses';

class RindegastosExpensesApi {
  async getExpenses(options) {
    const { filters, sort, sortBy, page, query, view, reload, mainFilter, companyId } = options;
    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `${endpointLocal}`,
      cacheItem: 'expenses-list',
      apiParams: {
        companyId,
        type: 'diary',
        filters,
        sort,
        sortBy,
        page,
        query,
        reload,
        orderDate: mainFilter?.orderDate,
        view
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'string', name: '_id' },
        { type: 'string', name: 'Supplier' },
        { type: 'string', name: 'Category' },
        { type: 'string', name: 'CategoryGroup' },
        { type: 'number', name: 'ReportId' }
      ],
      ...options
    });
  }

  async getExpense({ id, companyId, view = '' }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/${id}`,
      params: { companyId: companyId, view: view }
    });
    return response;
  }

  async getExpensesFromExpenseReport(options) {
    const { filters, sort, sortBy, page, query, view, reload, id, companyId } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `${endpointLocal}/${id}/expenses`,
      cacheItem: 'ExpensesFromExpenseReport',
      apiParams: {
        companyId,
        type: 'expenses-from-expenses-report',
        view,
        payload: { filters, sort, sortBy, page, query, reload, view }
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'string', name: '_id' },
        { type: 'string', name: 'Title' },
        { type: 'number', name: 'ReportNumber' },
        { type: 'string', name: 'EmployeeName' }
      ],
      ...options
    });
  }
}

export const rindegastosExpensesApi = new RindegastosExpensesApi();
