import { createContext, useState, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Toasty, useToasty } from '../hooks/toasty';
import { ThemeContext } from './theme-context';
import { UserContext } from './user-context';
import { getLocalSid } from '../services/gadmin';
import RouteReducer, { InitialtRoute } from './route-reducer';
import { useMounted } from '../hooks/use-mounted';
import { useData } from '../hooks/use-data';
import { goalMyAuthorizationApi } from '../api/goal/user/goal-my-authorization';
import { useSessionStorage } from '../hooks/local-access';
import { appUrls } from '../api/app-definitions';
import { useAuth } from '../hooks/use-auth';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [name] = useState('GOAL');
  const [logoText] = useState('Tu meta, mas na!');
  const [unauthorized, setUnauthorized] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [currentCompany, setCurrentCompany] = useSessionStorage('companyId', 0);
  const [currentCompanyInfo, setCurrentCompanyInfo] = useSessionStorage('companyInfo', {
    id: 0,
    name: ''
  });
  const [appUrlBase, setAppUrlBase] = useState('');
  const [initializing, setInitializing] = useState(true);
  const mounted = useMounted();
  const { isLogged } = useAuth();

  const selectCompany = (company) => {
    setCurrentCompany(company.id);
    setCurrentCompanyInfo(company);
  };

  const [companieState, handleRefreshCompanies] = useData({
    sourceApi: goalMyAuthorizationApi.getCompaniesList,
    loadingMessage: 'Cargando informacion de la empresa',
    mounted,
    defaults: {
      noAutoLoad: true
    }
  });

  useEffect(() => {
    setAppUrlBase(`/app/${currentCompany}`);
  }, [currentCompany]);

  const checkCompany = (selectedCompany) => {
    if (companieState?.data?.length === 0) {
      return false;
    }
    // eslint-disable-next-line eqeqeq
    const selectedItewm = companieState?.data?.find((item) => item.id == selectedCompany);
    // if (selectedItewm) {
    //   setCurrentCompany(selectedItewm.id);
    // }
    return selectedItewm;
  };

  useEffect(() => {
    if (companieState.isLoaded && companieState.success) {
      if (!currentCompany) {
        if (companieState?.data?.length === 0) {
          setUnauthorized(true);
        } else {
          const defaultCompanyId = companieState?.data[0].id;
          if (defaultCompanyId) {
            selectCompany(companieState?.data[0]);
            setAppUrlBase(`/app/${defaultCompanyId}`);
          }
        }
      }
      setInitializing(false);
    }
  }, [companieState]);
  const authenticationUrl = `${process.env.REACT_APP_KEYCLOAK_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/auth`;
  const localSid = getLocalSid();
  const [queueToasty, setQueueToasty] = useState([]);

  const [notify, showNotify] = useToasty({ queue: queueToasty, setQueue: setQueueToasty });
  const [routeState, routeDispacher] = useReducer(RouteReducer, InitialtRoute);
  const showError = (message) => {
    showNotify(message, 'error');
  };
  const showWarning = (message) => {
    showNotify(message, 'warning');
  };
  const title = (page) => `${page} | ${name}`;

  const handleShowHelp = () => {
    setShowHelp(!showHelp);
  };

  const getImageUrl = ({ imageCode, thumbMail = false }) => {
    const uniqueId = 'goal-image';
    if (thumbMail) return `${appUrls.MEDIA_HOST}/q/${imageCode}.${uniqueId}-thumb`;
    else return `${appUrls.MEDIA_HOST}/q/${imageCode}.${uniqueId}`;
  };

  // noImageUrl
  const noImageUrl = `${appUrls.MEDIA_HOST}/q/LH0-9x.goal-image`;

  useEffect(() => {
    if (isLogged) {
      handleRefreshCompanies();
    }
  }, [isLogged]);

  return (
    <AppContext.Provider
      value={{
        name,
        logoText,
        currentCompany,
        selectCompany,
        routeState,
        routeDispacher,
        notify,
        localSid,
        title,
        showNotify,
        showError,
        showWarning,
        authenticationUrl,
        isLoggedIn: isLogged,
        companyState: companieState,
        checkCompany,
        appUrlBase,
        unauthorized,
        setUnauthorized,
        initializing,
        showHelp,
        handleShowHelp,
        getImageUrl,
        noImageUrl,
        currentCompanyInfo,
        userCompanies: companieState?.data || []
      }}
    >
      {children}
      <Toasty action={notify} queue={queueToasty} setQueue={setQueueToasty} />
    </AppContext.Provider>
  );
};
AppProvider.propTypes = { children: PropTypes.node.isRequired };

const WithApp = (Child) => {
  const WithAppWrapper = (props) => (
    <AppContext.Consumer>
      {(appContext) => (
        <ThemeContext.Consumer>
          {(themeContext) => (
            <UserContext.Consumer>
              {(userContext) => (
                <Child
                  key={1}
                  {...props}
                  appContext={appContext}
                  themeContext={themeContext}
                  userContext={userContext}
                />
              )}
            </UserContext.Consumer>
          )}
        </ThemeContext.Consumer>
      )}
    </AppContext.Consumer>
  );

  WithAppWrapper.displayName = `WithApp(${Child.displayName || Child.name || 'Component'})`;

  return WithAppWrapper;
};

export { AppProvider, WithApp, AppContext };
