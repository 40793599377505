import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalMyAuthhorizationApi {
  async getCompaniesList() {
    const response = await dataLoader.loadGoalGetData({
      action: '/auth/users/my-companies'
    });
    return response;
  }

  async getMyBranchOffices({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/auth/users/my-branch-offices?companyId=${companyId}`
    });
    return response;
  }
}

export const goalMyAuthorizationApi = new GoalMyAuthhorizationApi();
