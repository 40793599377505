import { lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Authorization from './pages/Authorization';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';
import NotFound from './pages/Page404';
import Unauthorized from './pages/Page401';
import PageLog from './pages/utils/PageLog';
import { Invoices } from './containers/purchases/invoices';
import { Queues } from './containers/managment/queues';
import { Invoice } from './containers/purchases/invoice';
import { AuthGuard } from './components/auth-guard';
import { GuestGuard } from './components/guest-guard';
import GadminActions from './contexts/gadmin-actions';
import { AllowedGuard } from './hooks/use-allowed';
import { JournalReceiptPdf } from './containers/purchases/journal/print-receip-pdft';
import { PromoAssigned } from './containers/promo/promo-assigned';
import { Promo } from './containers/promo/promo';
import { CreditNotesService } from './containers/purchases/credit-notes-service';
import { CreditNoteService } from './containers/purchases/credit-note-service';
import { ReceiptsFees } from './containers/purchases/receipts-fees';
import { ReceiptFees } from './containers/purchases/receipt-fees';
import { CustomerReturnsReasons } from './containers/customer-returns/customer-returns-reasons';
import { CustomerReturns } from './containers/customer-returns/customer-returns';
import { CustomerReturn } from './containers/customer-returns/customer-return';
import { CustomerReturnsProducts } from './containers/customer-returns/customer-returns-products';
import { CustomerOrder } from './containers/sales/orders/customer-order';
import { CreditNotesItems } from './containers/purchases/credit-notes-items';
import { DebitNotesService } from './containers/purchases/debit-notes-service';
import { CustomerOrders } from './containers/sales/orders/customer-orders';
import { GProcDashboard } from './containers/managment/gproc-dashboard';
import { HRInvoicing } from './containers/delivery/hr/hr-invoicing';
import { AuxiliariesCurrent } from './containers/delivery/auxiliary/auxiliaries-current';
import { AuxiliaryHstPlaca } from './containers/delivery/auxiliary/auxiliary-hst-placa';
import { AuxiliaryHstDate } from './containers/delivery/auxiliary/auxiliary-hst-date';
import { HRInvoicingPendings } from './containers/delivery/hr/hr-invoicing-pending';
import { HRInvoicingAll } from './containers/delivery/hr/hr-invoicing-all';
import { HRInvoicingInvoiced } from './containers/delivery/hr/hr-invoicing-invoiced';
import { GProcDefaults } from './containers/managment/gproc-defaults';
import { FEPendingsSAP } from './containers/accounting/fe/fe-pendings-sap';
import { AccountingDashboard } from './containers/accounting/accounting-dashboard';
import { FEPendingsFacele } from './containers/accounting/fe/fe-pendings-facele';
import { Receipt } from './containers/sales/document/receipt';
import { FESentsFacele } from './containers/accounting/fe/fe-sents-facele';
import { Product } from './containers/inventory/product';
import { Customer } from './containers/busines-partners/customer';
import { CustomerOrdersCanceled } from './containers/sales/orders/customer-orders-canceled';
import { Picking } from './containers/inventory/picking';
import { PickingGroup } from './containers/inventory/pricking-group';
import { ProductScanner } from './containers/inventory/product-scanner';
import { Journal } from './containers/accounting/journal/journal';
import { ReceiptNumber } from './containers/sales/document/receipt-number';
import { CustomerOrdersOpenned } from './containers/sales/orders/customer-orders-openned';
import { CustomerOrdersPending } from './containers/sales/orders/pendings';
import { CustomerOrdersAuthorized } from './containers/sales/orders/customer-orders-authorized';
import { CustomerProductPurchases } from './containers/busines-partners/customer-product-purchases';
import Search from './pages/Search';
import { InvoiceItem } from './containers/purchases/invoice-item';
import { InvoicesItem } from './containers/purchases/invoices-item';
import { LoadingScreen } from './components/loading-screen';
import { DeliveryMonitoring } from './containers/delivery/monitoring/delivery-monitoring';
import DeliveryDocuments from './containers/delivery/monitoring/delivery-documents';
import { CustomerUnauthorizedOrders } from './containers/sales/orders/customer-unauthorized-orders';
import { DeliveryTab } from './containers/delivery/monitoring/delivery-tab';
import { DeliverySummary } from './containers/delivery/monitoring/delivery-summary';
import { DeliveryTeam } from './containers/delivery/monitoring/delivery-team';
import { DeliverySheets } from './containers/delivery/monitoring/delivery-sheets';
import { DeliveryExpense } from './containers/delivery/monitoring/delivery-expence';
import DeliveryNoteCredits from './containers/delivery/monitoring/delivery-note-credits';
import { SurveyCreate } from './containers/survey/questionnaire/survey-create';
import SurveyList from './containers/survey/questionnaire/survey-list';
import { DexReports } from './containers/gex/dex-reports';
import { DeliveryCreateGr } from './containers/delivery/create-gr/delivery-create-gr';
import DeliveryLiquidation from './containers/delivery/monitoring/delivery-liquidation';
import { SalesTeamsContainer } from './containers/sales-teams/sales-teams-container';
import { SalesTeamsTab } from './containers/sales-teams/sales-teams-tab';
import { SalesTeamsChannel } from './containers/sales-teams/sales-teams-channel';
import { SalesTeamsZones } from './containers/sales-teams/sales-teams-zones';
import { SalesTeamsPrices } from './containers/sales-teams/sales-teams-prices';
import { SalesTeamsRoutes } from './containers/sales-teams/sales-teams-routes';
import { SalesTeamsSellers } from './containers/sales-teams/sales-teams-sellers';
import { BusinessPartnerAddresses } from './components/busines-partner/panels/business-parther-addresses';
import { BusinessPartnerDivisions } from './components/busines-partner/panels/business-parther-divisions';
import { BusinessPartnerTables } from './components/busines-partner/panels/business-parther-tables';
import { BusinessPartnerDocuments } from './components/busines-partner/panels/business-parther-documents';
import { BusinessPartnerArticles } from './components/busines-partner/panels/business-parther-articles';
import { BusinessPartnerElectricInvoice } from './components/busines-partner/panels/business-parther-electric-invoice';
import { BusinessPartnersForm } from './containers/busines-partners/business-partners-form';
import { ProductForm } from './containers/inventory/product-form';
import { MaintenanceEmployeePosition } from './containers/master-data/payroll/maintenance-employee-position';
import { MaintenanceDepartments } from './containers/master-data/payroll/maintenance-departments';
import { MaintenanceTypesOfVauchers } from './containers/master-data/base/maintenance-types-of-vauchers';
import { MaintenancePaymentTerm } from './containers/master-data/base/maintenance-payment-terms';
import { CompanyLayout } from './layouts/dashboard/CompanyLayout';
import { useApp } from './hooks/use-app';
import { TablesProviders } from './containers/sales-teams/sales-teams-providers';
import { SalesTeamsBrands } from './containers/sales-teams/sales-teams-brands';
import { SalesTeamsProducts } from './containers/sales-teams/sales-teams-products';
import { ContestContainerAwards } from './components/contest/contest-container-awards';
import { ProductResumenPanel2 } from './components/inventory/product/panels/product-resumen2-panel';
import { DeliveryCreateGRTab } from './containers/delivery/create-gr/delivery-create-gr-tab';
import { DeliveryCreateGrDetail } from './components/delivery/create-gr/delivery-create-gr-detail';
import { DeliveryCreateGrDataDelivery } from './components/delivery/create-gr/delivery-create-gr-data-delivery';
import { BusinessPartnerSummaryContainer } from './components/busines-partner/panels/business-parther-summary-container';
import SurveyContainer from './containers/survey/survey/survey-container';
import { SurveyTab } from './containers/survey/survey/survey-tabs';
import { SurveyDetailContainer } from './containers/survey/survey/survey-detail-container';
import { ContestContainerQuota } from './components/contest/contest-container-quota';
import { IncidentsContainer } from './containers/incidents/incidents-container';
import { GREPendings } from './containers/accounting/fe/gre-pendings';
import { GoalImporter } from './containers/importer/goal-importer';
import { SalesTeamsSummary } from './containers/sales-teams/sales-teams-summary';
import { VehicleDetailContainer } from './containers/delivery/vehicle/vehicle-detail-container';
import { VehicleWorkOrders } from './containers/operations/vehicles/vehicle-work-orders';
import { IncidentsCreate } from './containers/incidents/incidents-create';
import { IncidentsTab } from './containers/incidents/incidents-tab';
import { ImportContainer } from './containers/importer/import-container';
import { IncidentsDetailContainer } from './containers/incidents/incidents-detail-container';
import { IncidentsTransactionsContainer } from './containers/incidents/incidents-transactions-container';
import { DeliveryPlanning } from './containers/delivery/monitoring/delivery-planning';
import { PresaleMonitoring } from './containers/presale/monitoring/presale-monitoring';
import { UploadContainer } from './containers/delivery/delivery-upload/upload-container';
import { MaintenanceIncidentCategories } from './containers/master-data/incidents/maintenance-incident-categories';
import { GREAll } from './containers/accounting/fe/gre-all';
import { BulksContainer } from './containers/automations/bulks/bulks-container';
import { BulkTab } from './containers/automations/bulks/bulk-tab';
import BulkQueues from './containers/automations/bulks/bulk-queues';
import QueuesContainer from './containers/automations/queues/queues-container';
import QueueDetailContainer from './containers/automations/queues/queue-detail-container';
import { PromotionProvider } from './contexts/promotions-context';
import { UsersProvider } from './contexts/core/users-context';
import { RolesProvider } from './contexts/core/roles-context';
import { PermissionsProvider } from './contexts/core/permissions-context';
import { ExpenseReportProvider } from './contexts/rindegastos/expense-report-context';
import { ExpenseProvider } from './contexts/rindegastos/expense-context';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const GoalServersContainer = Loadable(
  lazy(() =>
    import('./containers/automations/goal-servers/goal-servers').then((module) => ({
      default: module.GoalServersContainer
    }))
  )
);

const VehicleOdometers = Loadable(
  lazy(() =>
    import('./containers/operations/vehicles/vehicle-odometers').then((module) => ({
      default: module.VehicleOdometers
    }))
  )
);
const Vehicles = Loadable(
  lazy(() =>
    import('./containers/operations/vehicles/vehicles').then((module) => ({
      default: module.Vehicles
    }))
  )
);

const VehiclesTab = Loadable(
  lazy(() =>
    import('./containers/operations/vehicles/vehicles-tab').then((module) => ({
      default: module.VehiclesTab
    }))
  )
);
const DeliveryVehiclesTab = Loadable(
  lazy(() =>
    import('./containers/delivery/vehicle/delivery-vehicles-tab').then((module) => ({
      default: module.DeliveryVehiclesTab
    }))
  )
);
const DeliveryVehicleDrivers = Loadable(
  lazy(() =>
    import('./containers/delivery/drivers/delivery-vehicle-drivers').then((module) => ({
      default: module.DeliveryVehicleDrivers
    }))
  )
);
const DeliveryVehicles = Loadable(
  lazy(() =>
    import('./containers/delivery/vehicle/delivery-vehicles').then((module) => ({
      default: module.DeliveryVehicles
    }))
  )
);
const VehicleWorkOrderDetail = Loadable(
  lazy(() =>
    import('./containers/operations/vehicles/vehicle-work-order-detail').then((module) => ({
      default: module.VehicleWorkOrderDetail
    }))
  )
);

const ProductResumenPanel = Loadable(
  lazy(() =>
    import('./components/inventory/product/panels/product-resumen-panel').then((module) => ({
      default: module.ProductResumenPanel
    }))
  )
);
const ProductAccountingPanel = Loadable(
  lazy(() =>
    import('./components/inventory/product/panels/product-accounting-panel').then((module) => ({
      default: module.ProductAccountingPanel
    }))
  )
);
const ProductTeamsPanel = Loadable(
  lazy(() =>
    import('./components/inventory/product/panels/product-teams-panel').then((module) => ({
      default: module.ProductTeamsPanel
    }))
  )
);
const ProductGalleryPanel = Loadable(
  lazy(() =>
    import('./components/inventory/product/panels/product-gallery-panel').then((module) => ({
      default: module.ProductGalleryPanel
    }))
  )
);
const ProductStockPanel = Loadable(
  lazy(() =>
    import('./components/inventory/product/panels/product-stock-panel').then((module) => ({
      default: module.ProductStockPanel
    }))
  )
);
const ProductPricesPanel = Loadable(
  lazy(() =>
    import('./components/inventory/product/panels/product-prices-panel').then((module) => ({
      default: module.ProductPricesPanel
    }))
  )
);
const ProductCostsPanel = Loadable(
  lazy(() =>
    import('./components/inventory/product/panels/product-costs-panel').then((module) => ({
      default: module.ProductCostsPanel
    }))
  )
);
const ProductMeasuresPanel = Loadable(
  lazy(() =>
    import('./components/inventory/product/panels/product-measures-panel').then((module) => ({
      default: module.ProductMeasuresPanel
    }))
  )
);
const ProductAttributesPanel = Loadable(
  lazy(() =>
    import('./components/inventory/product/panels/product-attributes-panel').then((module) => ({
      default: module.ProductAttributesPanel
    }))
  )
);
const ResourceFileLogs = Loadable(
  lazy(() =>
    import('./containers/common/resource-file-logs').then((module) => ({
      default: module.ResourceFileLogs
    }))
  )
);
const GadminLogs = Loadable(
  lazy(() =>
    import('./containers/common/gadmin-logs').then((module) => ({
      default: module.GadminLogs
    }))
  )
);
const BusinessPartnerHeaderPanel = Loadable(
  lazy(() =>
    import('./components/busines-partner/panels/business-partner-header-panel').then((module) => ({
      default: module.BusinessPartnerHeaderPanel
    }))
  )
);

const BusinessPartnerSalesOrdersPanel = Loadable(
  lazy(() =>
    import('./components/busines-partner/panels/business-partner-customer-sales-panel').then(
      (module) => ({
        default: module.BusinessPartnerSalesOrdersPanel
      })
    )
  )
);

const WhatsappMessage = Loadable(
  lazy(() =>
    import('./containers/miscellaneous/whatsapp-message').then((module) => ({
      default: module.WhatsappMessage
    }))
  )
);

const PicShare = Loadable(
  lazy(() =>
    import('./containers/pic-share/share').then((module) => ({
      default: module.PicShare
    }))
  )
);

const KardexDiscrepancies = Loadable(
  lazy(() =>
    import('./containers/managment/kardex/karedex-discrepancies').then((module) => ({
      default: module.KardexDiscrepancies
    }))
  )
);

const ExpenseReports = Loadable(
  lazy(() =>
    import('./containers/rindegastos/expense-reports-container').then((module) => ({
      default: module.ExpenseReports
    }))
  )
);

const ExpenseReportTabs = Loadable(
  lazy(() =>
    import('./containers/rindegastos/expense-report-tabs').then((module) => ({
      default: module.ExpenseReportTabs
    }))
  )
);

const ExpenseReport = Loadable(
  lazy(() =>
    import('./containers/rindegastos/expense-report').then((module) => ({
      default: module.ExpenseReport
    }))
  )
);

const ExpenseReportExpenses = Loadable(
  lazy(() =>
    import('./containers/rindegastos/expense-report-expenses').then((module) => ({
      default: module.ExpenseReportExpenses
    }))
  )
);

const Expenses = Loadable(
  lazy(() =>
    import('./containers/rindegastos/expenses/expenses-container').then((module) => ({
      default: module.Expenses
    }))
  )
);

const ExpenseTabs = Loadable(
  lazy(() =>
    import('./containers/rindegastos/expenses/expense-tabs').then((module) => ({
      default: module.ExpenseTabs
    }))
  )
);

const Expense = Loadable(
  lazy(() =>
    import('./containers/rindegastos/expenses/expense').then((module) => ({
      default: module.Expense
    }))
  )
);

const GexReports = Loadable(
  lazy(() =>
    import('./containers/gex/gex-reports').then((module) => ({
      default: module.GexReports
    }))
  )
);

const GoalModules = Loadable(
  lazy(() =>
    import('./containers/core/goal-modules').then((module) => ({
      default: module.GoalModules
    }))
  )
);

const Users = Loadable(
  lazy(() =>
    import('./containers/core/users').then((module) => ({
      default: module.Users
    }))
  )
);

const UserTabs = Loadable(
  lazy(() =>
    import('./containers/core/user-tabs').then((module) => ({
      default: module.UserTabs
    }))
  )
);

const User = Loadable(
  lazy(() =>
    import('./containers/core/user').then((module) => ({
      default: module.User
    }))
  )
);

const UserRoles = Loadable(
  lazy(() =>
    import('./components/managment/users/user-roles').then((module) => ({
      default: module.UserRoles
    }))
  )
);
const UserPermissions = Loadable(
  lazy(() =>
    import('./components/managment/users/user-permissions').then((module) => ({
      default: module.UserPermissions
    }))
  )
);

const UserSap = Loadable(
  lazy(() =>
    import('./components/managment/users/user-sap').then((module) => ({
      default: module.UserSap
    }))
  )
);

const Roles = Loadable(
  lazy(() =>
    import('./containers/core/roles').then((module) => ({
      default: module.Roles
    }))
  )
);

const RoleTabs = Loadable(
  lazy(() =>
    import('./containers/core/role-tabs').then((module) => ({
      default: module.RoleTabs
    }))
  )
);

const Role = Loadable(
  lazy(() =>
    import('./containers/core/role').then((module) => ({
      default: module.Role
    }))
  )
);

const RolePermissions = Loadable(
  lazy(() =>
    import('./components/managment/roles/role-permissions').then((module) => ({
      default: module.RolePermissions
    }))
  )
);

const RoleUsers = Loadable(
  lazy(() =>
    import('./components/managment/roles/role-users').then((module) => ({
      default: module.RoleUsers
    }))
  )
);

const Permissions = Loadable(
  lazy(() =>
    import('./containers/core/permissions').then((module) => ({
      default: module.Permissions
    }))
  )
);

const PermissionTabs = Loadable(
  lazy(() =>
    import('./containers/core/permission-tabs').then((module) => ({
      default: module.PermissionTabs
    }))
  )
);

const Permission = Loadable(
  lazy(() =>
    import('./containers/core/permission').then((module) => ({
      default: module.Permission
    }))
  )
);

const PermissionRoles = Loadable(
  lazy(() =>
    import('./components/managment/permissions/permission-roles').then((module) => ({
      default: module.PermissionRoles
    }))
  )
);

const PermissionUsers = Loadable(
  lazy(() =>
    import('./components/managment/permissions/permission-users').then((module) => ({
      default: module.PermissionUsers
    }))
  )
);

const PermissionCreate = Loadable(
  lazy(() =>
    import('./components/managment/permissions/permission-create').then((module) => ({
      default: module.PermissionCreate
    }))
  )
);

const MaintenanceLineContainer = Loadable(
  lazy(() =>
    import('./containers/master-data/master/maintenance-lines-continer').then((module) => ({
      default: module.MaintenanceLineContainer
    }))
  )
);

const MaintenanceCompanies = Loadable(
  lazy(() =>
    import('./containers/master-data/base/maintenance-companies').then((module) => ({
      default: module.MaintenanceCompanies
    }))
  )
);

const MaintenanceAttachmentTypes = Loadable(
  lazy(() =>
    import('./containers/master-data/assets/maintenance-attachment-types').then((module) => ({
      default: module.MaintenanceAttachmentTypes
    }))
  )
);

const AttachmentTypeCreate = Loadable(
  lazy(() =>
    import('./components/maintenance/assets/attachment-type-create').then((module) => ({
      default: module.AttachmentTypeCreate
    }))
  )
);

const AttachmentTypeTabs = Loadable(
  lazy(() =>
    import('./containers/master-data/assets/attachment-type-tabs').then((module) => ({
      default: module.AttachmentTypeTabs
    }))
  )
);

const AttachmentType = Loadable(
  lazy(() =>
    import('./containers/master-data/assets/attachment-type').then((module) => ({
      default: module.AttachmentType
    }))
  )
);

const AttachmentTypeLines = Loadable(
  lazy(() =>
    import('./containers/master-data/assets/attachment-type-lines').then((module) => ({
      default: module.AttachmentTypeLines
    }))
  )
);

const MaintenanceAttachmentGroups = Loadable(
  lazy(() =>
    import('./containers/master-data/assets/maintenance-attachment-groups').then((module) => ({
      default: module.MaintenanceAttachmentGroups
    }))
  )
);

const AttachmentGroupCreate = Loadable(
  lazy(() =>
    import('./components/maintenance/assets/attachment-group-create').then((module) => ({
      default: module.AttachmentGroupCreate
    }))
  )
);

const AttachmentGroupTabs = Loadable(
  lazy(() =>
    import('./containers/master-data/assets/attachment-group-tabs').then((module) => ({
      default: module.AttachmentGroupTabs
    }))
  )
);

const AttachmentGroup = Loadable(
  lazy(() =>
    import('./containers/master-data/assets/attachment-group').then((module) => ({
      default: module.AttachmentGroup
    }))
  )
);

const MaintenanceAttachmentTemplates = Loadable(
  lazy(() =>
    import('./containers/master-data/assets/maintenance-attachment-templates').then((module) => ({
      default: module.MaintenanceAttachmentTemplates
    }))
  )
);

const AttachmentTemplateCreate = Loadable(
  lazy(() =>
    import('./components/maintenance/assets/attachment-template-create').then((module) => ({
      default: module.AttachmentTemplateCreate
    }))
  )
);

const AttachmentTemplateTabs = Loadable(
  lazy(() =>
    import('./containers/master-data/assets/attachment-template-tabs').then((module) => ({
      default: module.AttachmentTemplateTabs
    }))
  )
);

const AttachmentTemplate = Loadable(
  lazy(() =>
    import('./containers/master-data/assets/attachment-template').then((module) => ({
      default: module.AttachmentTemplate
    }))
  )
);

const AttachmentTemplateGroups = Loadable(
  lazy(() =>
    import('./containers/master-data/assets/attachment-template-groups').then((module) => ({
      default: module.AttachmentTemplateGroups
    }))
  )
);

const AttachmentTemplateGroup = Loadable(
  lazy(() =>
    import('./containers/master-data/assets/attachment-template-group').then((module) => ({
      default: module.AttachmentTemplateGroup
    }))
  )
);

const AttachmentTemplateGroupCreate = Loadable(
  lazy(() =>
    import('./containers/master-data/assets/attachment-template-group-create').then((module) => ({
      default: module.AttachmentTemplateGroupCreate
    }))
  )
);

const AttachmentTemplateLines = Loadable(
  lazy(() =>
    import('./containers/master-data/assets/attachment-template-lines').then((module) => ({
      default: module.AttachmentTemplateLines
    }))
  )
);

const AttachmentTemplateLineCreate = Loadable(
  lazy(() =>
    import('./containers/master-data/assets/attachment-template-line-create').then((module) => ({
      default: module.AttachmentTemplateLineCreate
    }))
  )
);

const AttachmentTemplateLine = Loadable(
  lazy(() =>
    import('./containers/master-data/assets/attachment-template-line').then((module) => ({
      default: module.AttachmentTemplateLine
    }))
  )
);

const BulkDetailContainer = Loadable(
  lazy(() =>
    import('./containers/automations/bulks/bulk-detail-container').then((module) => ({
      default: module.BulkDetailContainer
    }))
  )
);

const PaymentTermCreate = Loadable(
  lazy(() =>
    import('./components/maintenance/base/payment-term-create').then((module) => ({
      default: module.PaymentTermCreate
    }))
  )
);

const PaymentTermTabs = Loadable(
  lazy(() =>
    import('./containers/master-data/base/payment-term-tabs').then((module) => ({
      default: module.PaymentTermTabs
    }))
  )
);

const PaymentTerm = Loadable(
  lazy(() =>
    import('./containers/master-data/base/payment-term').then((module) => ({
      default: module.PaymentTerm
    }))
  )
);

/* master-data (begin)*/
const MaintenanceBusinessPartnerGroupsContainer = Loadable(
  lazy(() =>
    import('./containers/master-data/master/maintenance-business-partner-groups-container').then(
      (module) => ({
        default: module.MaintenanceBusinessPartnerGroupsContainer
      })
    )
  )
);
const MaintenanceBrandsContainer = Loadable(
  lazy(() =>
    import('./containers/master-data/master/maintenance-brands-container').then((module) => ({
      default: module.MaintenanceBrandsContainer
    }))
  )
);

const MaintenanceClassificationsContainer = Loadable(
  lazy(() =>
    import('./containers/master-data/master/maintenance-classifications-container').then(
      (module) => ({
        default: module.MaintenanceClassificationsContainer
      })
    )
  )
);

const MaintenanceCategoriesContainer = Loadable(
  lazy(() =>
    import('./containers/master-data/master/maintenance-categories-container').then((module) => ({
      default: module.MaintenanceCategoriesContainer
    }))
  )
);

const MaintenanceAttributeGroupsContainer = Loadable(
  lazy(() =>
    import('./containers/master-data/master/maintenance-attribute-groups-container').then(
      (module) => ({
        default: module.MaintenanceAttributeGroupsContainer
      })
    )
  )
);

const MaintenanceTerritoriesContainer = Loadable(
  lazy(() =>
    import('./containers/master-data/master/maintenance-territories-container').then((module) => ({
      default: module.MaintenanceTerritoriesContainer
    }))
  )
);

const MaintenanceLineOfBusiness = Loadable(
  lazy(() =>
    import('./containers/master-data/base/maintenance-line-of-business').then((module) => ({
      default: module.MaintenanceLineOfBusiness
    }))
  )
);

const MaintenanceDivisions = Loadable(
  lazy(() =>
    import('./containers/master-data/base/maintenance-divisions').then((module) => ({
      default: module.MaintenanceDivisions
    }))
  )
);

const MaintenanceSalesForceGroups = Loadable(
  lazy(() =>
    import('./containers/master-data/base/maintenance-sales-force-groups').then((module) => ({
      default: module.MaintenanceSalesForceGroups
    }))
  )
);

const SalesTeamGroupCreate = Loadable(
  lazy(() =>
    import('./containers/master-data/base/sales-team-group-create').then((module) => ({
      default: module.SalesTeamGroupCreate
    }))
  )
);

const SalesTeamGroupTabs = Loadable(
  lazy(() =>
    import('./containers/master-data/base/sales-team-group-tabs').then((module) => ({
      default: module.SalesTeamGroupTabs
    }))
  )
);

const SalesTeamGroup = Loadable(
  lazy(() =>
    import('./containers/master-data/base/sales-team-group').then((module) => ({
      default: module.SalesTeamGroup
    }))
  )
);

const MaintenanceBranchs = Loadable(
  lazy(() =>
    import('./containers/master-data/base/maintenance-branchs').then((module) => ({
      default: module.MaintenanceBranchs
    }))
  )
);
/* master-data (end)*/
/* commercial (begin)*/
const CustomerContest = Loadable(
  lazy(() =>
    import('./containers/contest/customer-contest').then((module) => ({
      default: module.CustomerContest
    }))
  )
);

const CustomerContestTab = Loadable(
  lazy(() =>
    import('./containers/contest/customer-contest-tab').then((module) => ({
      default: module.CustomerContestTab
    }))
  )
);

const ContestSummary = Loadable(
  lazy(() =>
    import('./components/contest/contest-summary').then((module) => ({
      default: module.ContestSummary
    }))
  )
);

const PromotionsList = Loadable(
  lazy(() =>
    import('./containers/promotions/promotions-container').then((module) => ({
      default: module.PromotionsList
    }))
  )
);

const PromotionCreate = Loadable(
  lazy(() =>
    import('./components/promotions/promotion-create').then((module) => ({
      default: module.PromotionCreate
    }))
  )
);

const PromotionsTab = Loadable(
  lazy(() =>
    import('./containers/promotions/promotions-tab').then((module) => ({
      default: module.PromotionsTab
    }))
  )
);

const PromotionSummary = Loadable(
  lazy(() =>
    import('./containers/promotions/promotions-summary').then((module) => ({
      default: module.PromotionSummary
    }))
  )
);

const PromotionsDetail = Loadable(
  lazy(() =>
    import('./containers/promotions/promotions-detail').then((module) => ({
      default: module.PromotionsDetail
    }))
  )
);

const PromotionChannels = Loadable(
  lazy(() =>
    import('./containers/promotions/promotion-channels').then((module) => ({
      default: module.PromotionChannels
    }))
  )
);

const PromotionsCustomerOnly = Loadable(
  lazy(() =>
    import('./containers/promotions/promotions-customer-only').then((module) => ({
      default: module.PromotionsCustomerOnly
    }))
  )
);

const PromotionsExcludeCustomers = Loadable(
  lazy(() =>
    import('./containers/promotions/promotions-exclude-customers').then((module) => ({
      default: module.PromotionsExcludeCustomers
    }))
  )
);

const PromotionsTables = Loadable(
  lazy(() =>
    import('./containers/promotions/promotions-tables').then((module) => ({
      default: module.PromotionsTables
    }))
  )
);

const PromotionsExclusive = Loadable(
  lazy(() =>
    import('./containers/promotions/promotions-exclusive').then((module) => ({
      default: module.PromotionsExclusive
    }))
  )
);

/* commercial (end)*/

const CustomerOrderDraft = Loadable(
  lazy(() =>
    import('./containers/sales/orders/customer-order-draft').then((module) => ({
      default: module.CustomerOrderDraft
    }))
  )
);

/** Reports (start) */
const ReportsSalesCommissions = Loadable(
  lazy(() =>
    import('./containers/reports/reports-sales-commisions').then((module) => ({
      default: module.ReportsSalesCommissions
    }))
  )
);

const ReportsSupervisorsCommissions = Loadable(
  lazy(() =>
    import('./containers/reports/reports-supervisors-commissions').then((module) => ({
      default: module.ReportsSupervisorsCommissions
    }))
  )
);

/** Reports (end) */
// ----------------------------------------------------------------------

export default function Router() {
  const { appUrlBase } = useApp();
  return useRoutes([
    {
      path: '',
      element: <LogoOnlyLayout />,
      children: [
        {
          path: 'app',
          element: (
            <AuthGuard>
              <DashboardLayout />
            </AuthGuard>
          ),
          children: [
            {
              path: ':company',
              element: <CompanyLayout />,
              children: [
                { path: '', element: <Navigate to={`${appUrlBase}/dashboard`} replace /> },
                { path: 'dashboard', element: <DashboardApp /> },
                { path: 'search', element: <Search /> },
                {
                  path: 'gadmin-logs',
                  element: (
                    <AllowedGuard permission={GadminActions.gCC_PROC_LOG}>
                      <GadminLogs />
                    </AllowedGuard>
                  )
                },
                {
                  path: 'purchases',
                  children: [
                    { path: '', element: <Navigate to="/app/purchases/invoices" replace /> },
                    {
                      path: 'invoices',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_PURCHASE_FFEE_LIST}>
                              <Invoices />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_PURCHASE_FFEE_LIST}>
                              <Invoice />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'invoice-items',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_PURCHASE_FFEE_ITEM_LIST}>
                              <InvoicesItem />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_PURCHASE_FFEE_ITEM_LIST}>
                              <InvoiceItem />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'receipts-fees',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_PURCHASE_RRHH_LIST}>
                              <ReceiptsFees />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_PURCHASE_RRHH_LIST}>
                              <ReceiptFees />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'nc-items',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_PURCHASE_FFEE_LIST}>
                              <CreditNotesItems />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_PURCHASE_FFEE_LIST}>
                              <CreditNotesItems />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },

                    {
                      path: 'nc-service',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_PURCHASE_NCES_LIST}>
                              <CreditNotesService />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_PURCHASE_NCES_LIST}>
                              <CreditNoteService />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'dn-service',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_PURCHASE_NCES_LIST}>
                              <DebitNotesService />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_PURCHASE_NCES_LIST}>
                              <DebitNotesService />
                            </AllowedGuard>
                          )
                        }
                      ]
                    }
                  ]
                },
                {
                  path: 'promo',
                  children: [
                    {
                      path: '',
                      element: (
                        <AllowedGuard permission={GadminActions.gCC_BROWSE_ORDER_REG}>
                          <Promo />
                        </AllowedGuard>
                      )
                    },
                    {
                      path: 'assigneds',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_BROWSE_ORDER_REG}>
                              <Promo />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_BROWSE_ORDER_REG}>
                              <PromoAssigned />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },

                    {
                      path: ':id',
                      element: (
                        <AllowedGuard permission={GadminActions.gCC_BROWSE_ORDER_REG}>
                          <Invoice />
                        </AllowedGuard>
                      )
                    }
                  ]
                },
                {
                  path: 'journal',
                  children: [
                    {
                      path: '',
                      element: (
                        <AllowedGuard permission={GadminActions.gCC_ACCT_ACCOUNT_DET}>
                          <Invoices />
                        </AllowedGuard>
                      )
                    },
                    {
                      path: ':id',
                      element: (
                        <AllowedGuard permission={GadminActions.gCC_ACCT_ACCOUNT_DET}>
                          <JournalReceiptPdf />
                        </AllowedGuard>
                      )
                    }
                  ]
                },
                {
                  path: 'inventory',
                  children: [
                    {
                      path: 'products',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                              <Product />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'new',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                              <ProductForm itemcode={'new'} />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'scan',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                              <ProductScanner />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':itemcode',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                              <Product />
                            </AllowedGuard>
                          ),
                          children: [
                            {
                              path: '',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                                  <ProductResumenPanel />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'summary2',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                                  <ProductResumenPanel2 />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'accounting',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                                  <ProductAccountingPanel />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'gallery',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                                  <ProductGalleryPanel />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'stock',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                                  <ProductStockPanel />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'prices',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                                  <ProductPricesPanel />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'costs',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                                  <ProductCostsPanel />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'measures',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                                  <ProductMeasuresPanel />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'teams',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                                  <ProductTeamsPanel />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'attributes',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                                  <ProductAttributesPanel />
                                </AllowedGuard>
                              )
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'picking',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                              <Picking />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'groups',
                          children: [
                            {
                              path: '',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                                  <PickingGroup />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: ':id',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                                  <PickingGroup />
                                </AllowedGuard>
                              )
                            }
                          ]
                        },
                        {
                          path: ':id',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                              <Picking />
                            </AllowedGuard>
                          )
                        }
                      ]
                    }
                  ]
                },
                {
                  path: 'busines-partners',
                  children: [
                    {
                      path: 'customers',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_PROC_CLIENT_EDIT}>
                              <Customer />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'search',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_PROC_CLIENT_EDIT}>
                              <Customer />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':cardcode',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_PROC_CLIENT_EDIT}>
                              <Customer />
                            </AllowedGuard>
                          ),
                          children: [
                            {
                              path: '',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_PROC_CLIENT_EDIT}>
                                  <BusinessPartnerHeaderPanel />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'summary2',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_PROC_CLIENT_EDIT}>
                                  <BusinessPartnerSummaryContainer />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'sales-orders',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_BROWSE_ORDER_REG}>
                                  <BusinessPartnerSalesOrdersPanel />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'returns',
                              element: (
                                <AllowedGuard permission={GadminActions.G2_CD_LIST}>
                                  {/* <Customer /> */}
                                  <div>No implementado</div>
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'product-purchases',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_PROC_CLIENT_EDIT}>
                                  <CustomerProductPurchases />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'addresses',
                              element: (
                                <AllowedGuard permission={GadminActions.G2_CD_LIST}>
                                  <BusinessPartnerAddresses />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'divisions',
                              element: (
                                <AllowedGuard permission={GadminActions.G2_CD_LIST}>
                                  <BusinessPartnerDivisions />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'tables',
                              element: (
                                <AllowedGuard permission={GadminActions.G2_CD_LIST}>
                                  <BusinessPartnerTables />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'documents',
                              element: (
                                <AllowedGuard permission={GadminActions.G2_CD_LIST}>
                                  <BusinessPartnerDocuments />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'articles',
                              element: (
                                <AllowedGuard permission={GadminActions.G2_CD_LIST}>
                                  <BusinessPartnerArticles />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'electric_invoice',
                              element: (
                                <AllowedGuard permission={GadminActions.G2_CD_LIST}>
                                  <BusinessPartnerElectricInvoice />
                                </AllowedGuard>
                              )
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'new',
                      element: (
                        <AllowedGuard permission={GadminActions.gCC_PROC_CLIENT_EDIT}>
                          <BusinessPartnersForm />
                        </AllowedGuard>
                      )
                    }
                  ]
                },
                {
                  path: 'delivery',
                  children: [
                    {
                      path: 'invoicing',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_OPEN_ORDERS}>
                              <HRInvoicing />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'pendings',
                          children: [
                            {
                              path: ':id',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_OPEN_ORDERS}>
                                  <HRInvoicingPendings />
                                </AllowedGuard>
                              )
                            }
                          ]
                        },
                        {
                          path: 'invoiced',
                          children: [
                            {
                              path: ':id',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_OPEN_ORDERS}>
                                  <HRInvoicingInvoiced />
                                </AllowedGuard>
                              )
                            }
                          ]
                        },
                        {
                          path: 'all',
                          children: [
                            {
                              path: ':id',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_OPEN_ORDERS}>
                                  <HRInvoicingAll />
                                </AllowedGuard>
                              )
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'auxiliary',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_DELIVERY_PAR_APOYOREP}>
                              <AuxiliariesCurrent />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'hst-vehicle',
                          children: [
                            {
                              path: ':id',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_DELIVERY_PAR_APOYOREP}>
                                  <AuxiliaryHstPlaca />
                                </AllowedGuard>
                              )
                            }
                          ]
                        },
                        {
                          path: 'hst-date',
                          children: [
                            {
                              path: ':id',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_DELIVERY_PAR_APOYOREP}>
                                  <AuxiliaryHstDate />
                                </AllowedGuard>
                              )
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'vehicles',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
                              <DeliveryVehicles />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
                              <DeliveryVehiclesTab />
                            </AllowedGuard>
                          ),
                          children: [
                            {
                              path: '',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
                                  <VehicleDetailContainer />
                                </AllowedGuard>
                              )
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'drivers',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_DELIVERY_CHOFERES}>
                              <DeliveryVehicleDrivers />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: ':id',
                      element: (
                        <AllowedGuard permission={GadminActions.G2_PURCHASE_FFEE_LIST}>
                          <JournalReceiptPdf />
                        </AllowedGuard>
                      )
                    },
                    {
                      path: 'monitoring',
                      // element: (
                      //   <AllowedGuard permission={GadminActions.gCC_TDOY_SUMARY}>
                      //     <DeliveryMonitoring />
                      //   </AllowedGuard>
                      // ),
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_TDOY_LIST}>
                              <DeliveryMonitoring />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_TDOY_DET}>
                              <DeliveryTab />
                            </AllowedGuard>
                          ),
                          children: [
                            {
                              path: '',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_TDOY_SUMARY}>
                                  <DeliverySummary />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'documents',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_TDOY_DET}>
                                  <DeliveryDocuments />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'team',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_TDOY_TEAM_LIST}>
                                  <DeliveryTeam />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'sheets',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_TDOY_HR_LIST}>
                                  <DeliverySheets />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'spenses',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_TDOY_EXPENSE_LIST}>
                                  <DeliveryExpense />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'note-credits',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_TDOY_EXPENSE_LIST}>
                                  <DeliveryNoteCredits />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'liquidation',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_TDOY_EXPENSE_LIST}>
                                  <DeliveryLiquidation />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'inventory',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_TDOY_EXPENSE_LIST}>
                                  <DeliveryNoteCredits />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'invoice',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_TDOY_EXPENSE_LIST}>
                                  <DeliveryNoteCredits />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'pick-up',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_TDOY_EXPENSE_LIST}>
                                  <DeliveryNoteCredits />
                                </AllowedGuard>
                              )
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'create-gr',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_TDOY_LIST}>
                              <DeliveryCreateGr />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_TDOY_DET}>
                              <DeliveryCreateGRTab />
                            </AllowedGuard>
                          ),
                          children: [
                            {
                              path: '',
                              element: (
                                <AllowedGuard permission={GadminActions.G2_CONTESTS_LIST}>
                                  <DeliveryCreateGrDetail />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'data-delivery',
                              element: (
                                <AllowedGuard permission={GadminActions.G2_CONTESTS_LIST}>
                                  <DeliveryCreateGrDataDelivery />
                                </AllowedGuard>
                              )
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'planning',
                      children: [
                        // {
                        //   path: '',
                        //   element: (
                        //     <AllowedGuard permission={GadminActions.gCC_TDOY_LIST}>
                        //       <DeliveryPlanning />
                        //     </AllowedGuard>
                        //   )
                        // },
                        {
                          path: ':branchsCode',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_TDOY_LIST}>
                              <DeliveryPlanning />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'upload',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_TDOY_LIST}>
                              <UploadContainer />
                            </AllowedGuard>
                          )
                        }
                      ]
                    }
                  ]
                },
                {
                  path: 'sales',
                  children: [
                    {
                      path: '',
                      element: (
                        <AllowedGuard permission={GadminActions.gCC_BROWSE_ORDER_REG}>
                          <Invoices />
                        </AllowedGuard>
                      )
                    },
                    {
                      path: 'receipts',
                      children: [
                        {
                          path: 'number',
                          children: [
                            {
                              path: '',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_DET_ORDER_FAC}>
                                  <ReceiptNumber />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: ':id',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_DET_ORDER_FAC}>
                                  <ReceiptNumber />
                                </AllowedGuard>
                              )
                            }
                          ]
                        },
                        {
                          path: ':id',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_DET_ORDER_FAC}>
                              <Receipt />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'orders',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_BROWSE_ORDER_REG}>
                              <CustomerOrders />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'pendings',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_BROWSE_ORDER_REG}>
                              <CustomerOrdersPending />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'authorizeds',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_BROWSE_ORDER_REG}>
                              <CustomerOrdersAuthorized />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'unauthorizeds',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_BROWSE_ORDER_RET}>
                              <CustomerUnauthorizedOrders />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'cancelleds',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_BROWSE_ORDER_REG}>
                              <CustomerOrdersCanceled />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'openneds',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_BROWSE_ORDER_REG}>
                              <CustomerOrdersOpenned />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          children: [
                            {
                              path: '',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_BROWSE_ORDER_REG}>
                                  <CustomerOrder />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'draft',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_BROWSE_ORDER_REG}>
                                  <CustomerOrderDraft />
                                </AllowedGuard>
                              )
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'returns',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_CD_LIST}>
                              <CustomerReturns />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'reasons',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_CD_LIST}>
                              <CustomerReturnsReasons />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'products',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_CD_LIST}>
                              <CustomerReturnsProducts />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'pendings',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_CD_LIST}>
                              <CustomerReturnsReasons />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_CD_LIST}>
                              <CustomerReturn />
                            </AllowedGuard>
                          )
                        }
                      ]
                    }
                  ]
                },
                {
                  path: 'managment',
                  children: [
                    {
                      path: '',
                      element: (
                        <AllowedGuard permission={GadminActions.gCC_GPROC_MNGMT}>
                          <GProcDashboard />
                        </AllowedGuard>
                      )
                    },
                    {
                      path: 'config',
                      element: (
                        <AllowedGuard permission={GadminActions.gCC_GPROC_MNGMT}>
                          <GProcDefaults />
                        </AllowedGuard>
                      )
                    },
                    {
                      path: 'expense-reports',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                              <ExpenseReports />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: (
                            <ExpenseReportProvider>
                              <ExpenseReportTabs />
                            </ExpenseReportProvider>
                          ),
                          children: [
                            {
                              path: '',
                              element: (
                                <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                                  <ExpenseReport />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'expenses',
                              element: (
                                <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                                  <ExpenseReportExpenses />
                                </AllowedGuard>
                              )
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'expenses',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                              <Expenses />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: (
                            <ExpenseProvider>
                              <ExpenseTabs />
                            </ExpenseProvider>
                          ),
                          children: [
                            {
                              path: '',
                              element: (
                                <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                                  <Expense />
                                </AllowedGuard>
                              )
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'gex-reports',
                      element: (
                        <AllowedGuard permission={GadminActions.gCC_GEX_RESPORTS_LIST}>
                          <GexReports />
                        </AllowedGuard>
                      )
                    },
                    {
                      path: 'dex-reports',
                      element: (
                        <AllowedGuard permission={GadminActions.gCC_GEX_RESPORTS_LIST}>
                          <DexReports />
                        </AllowedGuard>
                      )
                    },
                    {
                      path: 'queues',
                      element: (
                        <AllowedGuard permission={GadminActions.gCC_GPROC_MNGMT}>
                          <Queues />
                        </AllowedGuard>
                      ),
                      children: [
                        {
                          path: ':id',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_GPROC_MNGMT}>
                              <JournalReceiptPdf />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'import',
                      element: (
                        <AllowedGuard permission={GadminActions.gCC_GEX_RESPORTS_LIST}>
                          <ImportContainer />
                        </AllowedGuard>
                      )
                    },
                    {
                      path: 'modules',
                      element: (
                        <AllowedGuard permission={GadminActions.gCC_GEX_RESPORTS_LIST}>
                          <GoalModules />
                        </AllowedGuard>
                      )
                    },
                    {
                      path: 'users',
                      children: [
                        {
                          path: '',
                          element: <Users />
                        },
                        {
                          path: ':id',
                          element: (
                            <UsersProvider>
                              <UserTabs />
                            </UsersProvider>
                          ),
                          children: [
                            {
                              path: '',
                              element: <User />
                            },
                            {
                              path: 'roles',
                              element: <UserRoles />
                            },
                            {
                              path: 'permissions',
                              element: <UserPermissions />
                            },
                            {
                              path: 'sap',
                              element: <UserSap />
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'roles',
                      children: [
                        {
                          path: '',
                          element: <Roles />
                        },
                        {
                          path: ':id',
                          element: (
                            <RolesProvider>
                              <RoleTabs />
                            </RolesProvider>
                          ),
                          children: [
                            {
                              path: '',
                              element: <Role />
                            },
                            {
                              path: 'permissions',
                              element: <RolePermissions />
                            },
                            {
                              path: 'users',
                              element: <RoleUsers />
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'permissions',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_GEX_RESPORTS_LIST}>
                              <Permissions />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'create',
                          element: <PermissionCreate />
                        },
                        {
                          path: ':id',
                          element: (
                            <PermissionsProvider>
                              <PermissionTabs />
                            </PermissionsProvider>
                          ),
                          children: [
                            {
                              path: '',
                              element: <Permission />
                            },
                            {
                              path: 'roles',
                              element: <PermissionRoles />
                            },
                            {
                              path: 'users',
                              element: <PermissionUsers />
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  path: 'accounting',
                  children: [
                    {
                      path: 'fe',
                      children: [
                        {
                          path: 'sents-facele',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_FACELE_SEND}>
                              <FESentsFacele />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'pendings-sap',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_FACELE_SEND}>
                              <FEPendingsSAP />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'pendings-facele',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_FACELE_SEND}>
                              <FEPendingsFacele />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'gre',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_FACELE_SEND}>
                              <GREAll />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'gre-pendings-facele',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_FACELE_SEND}>
                              <GREPendings />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'journals',
                      children: [
                        {
                          path: ':id',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_ACCT_JOURNAL_VIEW}>
                              <Journal />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: '',
                      element: (
                        <AllowedGuard permission={GadminActions.gCC_ACCT_PROCESS}>
                          <AccountingDashboard />
                        </AllowedGuard>
                      )
                    },
                    {
                      path: 'balance',
                      element: (
                        <AllowedGuard permission={GadminActions.G2_KARDEX_PROCESS}>
                          <KardexDiscrepancies />
                        </AllowedGuard>
                      )
                    }
                  ]
                },
                {
                  path: 'logs',
                  children: [
                    {
                      path: 'document-uploads',
                      element: (
                        <AllowedGuard permission={GadminActions.G2_PURCHASE_FFEE_SERVICE_UP}>
                          <ResourceFileLogs />
                        </AllowedGuard>
                      )
                    },
                    {
                      path: 'gadmin-logs',
                      element: (
                        <AllowedGuard permission={GadminActions.gCC_PROC_LOG}>
                          <GadminLogs />
                        </AllowedGuard>
                      )
                    }
                  ]
                },

                // SURVEY

                {
                  path: 'survey',
                  children: [
                    {
                      path: 'create',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <SurveyCreate />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'questionnaire',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_LIST}>
                              <SurveyList />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'survey',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_SURVEY_LIST}>
                              <SurveyContainer />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_SURVEY_LIST}>
                              <SurveyTab />
                            </AllowedGuard>
                          ),
                          children: [
                            {
                              path: '',
                              element: (
                                <AllowedGuard permission={GadminActions.G2_SURVEY_LIST}>
                                  <SurveyDetailContainer />
                                </AllowedGuard>
                              )
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                // End SURVEY

                {
                  path: 'commercial',
                  children: [
                    {
                      path: 'sales-commissions',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                              <ReportsSalesCommissions />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'supervisors-commissions',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                              <ReportsSupervisorsCommissions />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'promotion',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <PromotionsList />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'new',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <PromotionCreate />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_TDOY_DET}>
                              <PromotionProvider>
                                <PromotionsTab />
                              </PromotionProvider>
                            </AllowedGuard>
                          ),
                          children: [
                            {
                              path: '',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_TDOY_SUMARY}>
                                  <PromotionSummary />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'detail',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_TDOY_DET}>
                                  <PromotionsDetail />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'channel',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_TDOY_DET}>
                                  <PromotionChannels />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'customers-only',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_TDOY_DET}>
                                  <PromotionsCustomerOnly />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'exclude-customers',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_TDOY_DET}>
                                  <PromotionsExcludeCustomers />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'tables',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_TDOY_DET}>
                                  <PromotionsTables />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'exclusive',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_TDOY_DET}>
                                  <PromotionsExclusive />
                                </AllowedGuard>
                              )
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'contest',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_CONTESTS_LIST}>
                              <CustomerContest />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_CONTESTS_LIST}>
                              <CustomerContestTab />
                            </AllowedGuard>
                          ),
                          children: [
                            {
                              path: '',
                              element: (
                                <AllowedGuard permission={GadminActions.G2_CONTESTS_LIST}>
                                  <ContestSummary />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'awards',
                              element: (
                                <AllowedGuard permission={GadminActions.G2_CONTESTS_LIST}>
                                  <ContestContainerAwards />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'quota',
                              element: (
                                <AllowedGuard permission={GadminActions.G2_CONTESTS_LIST}>
                                  <ContestContainerQuota />
                                </AllowedGuard>
                              )
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'sales-teams',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_SALE_TEAMS}>
                              <SalesTeamsContainer />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_SALE_TEAMS}>
                              <SalesTeamsTab />
                            </AllowedGuard>
                          ),
                          children: [
                            {
                              path: '',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_SALE_TEAMS}>
                                  <SalesTeamsSummary />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'channel',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_SALE_TEAMS}>
                                  <SalesTeamsChannel />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'zones',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_SALE_TEAMS}>
                                  <SalesTeamsZones />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'prices',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_SALE_TEAMS}>
                                  <SalesTeamsPrices />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'routes',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_SALE_TEAMS}>
                                  <SalesTeamsRoutes />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'sellers',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_SALE_TEAMS}>
                                  <SalesTeamsSellers />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'providers',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_SALE_TEAMS}>
                                  <TablesProviders />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'brands',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_SALE_TEAMS}>
                                  <SalesTeamsBrands />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'products',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_SALE_TEAMS}>
                                  <SalesTeamsProducts />
                                </AllowedGuard>
                              )
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'pre-sale',
                      children: [
                        // {
                        //   path: '',
                        //   element: (
                        //     <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                        //       <PresaleMonitoring />
                        //     </AllowedGuard>
                        //   )
                        // },
                        {
                          path: ':teamCode',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_BROWSE_ORDER_DIG}>
                              <PresaleMonitoring />
                            </AllowedGuard>
                          )
                        }
                      ]
                    }
                  ]
                },

                // Master data
                {
                  path: 'master-data',
                  children: [
                    {
                      path: 'business-partner-group',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <MaintenanceBusinessPartnerGroupsContainer />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'brand',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <MaintenanceBrandsContainer />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'lines',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <MaintenanceLineContainer />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'classification',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <MaintenanceClassificationsContainer />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'categories',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <MaintenanceCategoriesContainer />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'attribute-groups',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <MaintenanceAttributeGroupsContainer />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'territories',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <MaintenanceTerritoriesContainer />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'line-of-business',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <MaintenanceLineOfBusiness />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'divisions',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <MaintenanceDivisions />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'companies',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <MaintenanceCompanies />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'branchs',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <MaintenanceBranchs />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'employee-position',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <MaintenanceEmployeePosition />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'departments',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_TEAM_BRANDS}>
                              <MaintenanceDepartments />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'types-of-vouchers',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <MaintenanceTypesOfVauchers />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'sales-force-groups',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <MaintenanceSalesForceGroups />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'create',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <SalesTeamGroupCreate />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: <SalesTeamGroupTabs />,
                          children: [
                            {
                              path: '',
                              element: <SalesTeamGroup />
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'payment-terms',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <MaintenancePaymentTerm />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'create',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                              <PaymentTermCreate />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                              <PaymentTermTabs />
                            </AllowedGuard>
                          ),
                          children: [
                            {
                              path: '',
                              element: <PaymentTerm />
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'incident-categories',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <MaintenanceIncidentCategories />
                            </AllowedGuard>
                          )
                        }
                      ]
                    },
                    {
                      path: 'attachment-types',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <MaintenanceAttachmentTypes />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'create',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <AttachmentTypeCreate />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: <AttachmentTypeTabs />,
                          children: [
                            {
                              path: '',
                              element: <AttachmentType />
                            },
                            {
                              path: 'lines',
                              element: <AttachmentTypeLines />
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'attachment-templates',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <MaintenanceAttachmentTemplates />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'create',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <AttachmentTemplateCreate />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: <AttachmentTemplateTabs />,
                          children: [
                            {
                              path: '',
                              element: <AttachmentTemplate />
                            },
                            {
                              path: 'lines',
                              children: [
                                {
                                  path: '',
                                  element: <AttachmentTemplateLines />
                                },
                                {
                                  path: 'create',
                                  element: <AttachmentTemplateLineCreate />
                                },
                                {
                                  path: ':lineId',
                                  element: <AttachmentTemplateLine />
                                }
                              ]
                            },
                            {
                              path: 'groups',
                              children: [
                                {
                                  path: '',
                                  element: <AttachmentTemplateGroups />
                                },
                                {
                                  path: 'create',
                                  element: <AttachmentTemplateGroupCreate />
                                },
                                {
                                  path: ':groupId',
                                  element: <AttachmentTemplateGroup />
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'attachment-groups',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <MaintenanceAttachmentGroups />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: 'create',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
                              <AttachmentGroupCreate />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: <AttachmentGroupTabs />,
                          children: [
                            {
                              path: '',
                              element: <AttachmentGroup />
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },

                // Incidents
                {
                  path: 'incidents',
                  children: [
                    {
                      path: 'incidents',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_SURVEY_LIST}>
                              <IncidentsContainer />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_TDOY_DET}>
                              <IncidentsTab />
                            </AllowedGuard>
                          ),
                          children: [
                            {
                              path: '',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_TDOY_SUMARY}>
                                  <IncidentsDetailContainer />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'transactions',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_TDOY_SUMARY}>
                                  <IncidentsTransactionsContainer />
                                </AllowedGuard>
                              )
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'create-incidents',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_SURVEY_LIST}>
                              <IncidentsCreate />
                            </AllowedGuard>
                          )
                        }
                      ]
                    }
                  ]
                },

                // Bulks
                {
                  path: 'automations',
                  children: [
                    {
                      path: 'bulks',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                              <BulksContainer />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                              <BulkTab />
                            </AllowedGuard>
                          ),
                          children: [
                            {
                              path: '',
                              element: (
                                <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                                  <BulkDetailContainer />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'queues',
                              element: (
                                <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                                  <BulkQueues />
                                </AllowedGuard>
                              )
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'queues',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.G2_QUEUE_LIST}>
                              <QueuesContainer />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          children: [
                            {
                              path: '',
                              element: (
                                <AllowedGuard permission={GadminActions.G2_QUEUE_LIST}>
                                  <QueueDetailContainer />
                                </AllowedGuard>
                              )
                            }
                          ]
                        }
                      ]
                    },
                    // goal servers
                    {
                      path: 'goal-servers',
                      children: [
                        {
                          path: '',
                          element: <GoalServersContainer />
                        },
                        {
                          path: ':id',
                          children: [
                            {
                              path: '',
                              element: <div />
                              // element: <GoalServerDetailContainer />
                            }
                          ]
                        }
                      ],
                      errorElement: <div />
                    }
                  ]
                },

                // operations
                {
                  path: 'operations',
                  children: [
                    {
                      path: 'vehicles',
                      children: [
                        {
                          path: '',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
                              <Vehicles />
                            </AllowedGuard>
                          )
                        },
                        {
                          path: ':id',
                          element: (
                            <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
                              <VehiclesTab />
                            </AllowedGuard>
                          ),
                          children: [
                            {
                              path: '',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
                                  <VehicleDetailContainer />
                                </AllowedGuard>
                              )
                            },
                            {
                              path: 'work-orders',
                              children: [
                                {
                                  path: '',
                                  element: (
                                    <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
                                      <VehicleWorkOrders />
                                    </AllowedGuard>
                                  )
                                },
                                {
                                  path: ':workReference',
                                  element: (
                                    <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
                                      <VehicleWorkOrderDetail />
                                    </AllowedGuard>
                                  )
                                }
                              ]
                            },
                            // odometers
                            {
                              path: 'odometers',
                              element: (
                                <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
                                  <VehicleOdometers />
                                </AllowedGuard>
                              )
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },

                { path: 'products', element: <Products /> },
                { path: 'import', element: <GoalImporter /> },
                { path: 'whatsapp', element: <WhatsappMessage /> },
                { path: 'pic-share', element: <PicShare /> },
                { path: 'log', element: <PageLog /> },
                {
                  path: 'blog',
                  element: <Blog />,
                  children: [
                    { path: 'login', element: <Login /> },
                    { path: 'register', element: <Register /> },
                    { path: '404', element: <NotFound /> },
                    { path: '', element: <Navigate to="/app" /> },
                    { path: '*', element: <Navigate to="/404" /> }
                  ]
                }
              ] // end company children
            },
            { path: 'whatsapp', element: <WhatsappMessage /> },
            { path: 'import', element: <GoalImporter /> },

            { path: 'log', element: <PageLog /> },
            {
              path: 'logs',
              children: [
                {
                  path: 'document-uploads',
                  element: (
                    <AllowedGuard permission={GadminActions.G2_PURCHASE_FFEE_SERVICE_UP}>
                      <ResourceFileLogs />
                    </AllowedGuard>
                  )
                },
                {
                  path: 'gadmin-logs',
                  element: (
                    <AllowedGuard permission={GadminActions.gCC_PROC_LOG}>
                      <GadminLogs />
                    </AllowedGuard>
                  )
                }
              ]
            }
          ]
        },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'authorization',
          element: (
            <GuestGuard>
              <Authorization />
            </GuestGuard>
          )
        },
        // { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '401', element: <Unauthorized /> },
        // { path: 'purchase', element: <PageLog /> },
        { path: '', element: <Navigate to={`${appUrlBase}/dashboard`} /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
