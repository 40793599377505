import PropTypes from 'prop-types';
import { useApp } from '../hooks/use-app';
import GoalButton from './goal-button';
import StarIcon from '@mui/icons-material/Star';
import { Typography } from '@mui/material';

export const GoalRecordLink = (props) => {
  const {
    label = '-',
    title = 'Ver detalle',
    to,
    onClick,
    children,
    fullPath = false,
    target = '_self'
  } = props;
  const { appUrlBase } = useApp();
  const detailRoute = to ? `${appUrlBase}${to}` : null;

  return (
    <GoalButton
      to={fullPath ? to : detailRoute}
      label={`${title ?? label}`}
      startIcon={<StarIcon fontSize="small" color="secondary" sx={{ fontSize: 1 }} />}
      variant="text"
      onClick={onClick}
      target={target}
    >
      {children ?? (
        <Typography variant="button" color="textPrimary">
          {label}
        </Typography>
      )}
    </GoalButton>
  );
};

export default GoalRecordLink;

GoalRecordLink.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
  fullPath: PropTypes.bool,
  target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top', 'framename'])
};
