import { ConstantStatus } from './application-enums';

const appDefinitions = {
  uid: 'dc4ef54715eb0ce748d1789b7c122e7b',
  name: process.env.REACT_APP_NAME,
  stage: process.env.REACT_APP_STAGE,
  appId: `${process.env.REACT_APP_NAME}-${process.env.REACT_APP_STAGE}`.toLowerCase(),
  pageSize: 50,
  version: process.env.REACT_APP_VERSION ?? '1.0.0+1'
};

const appUrls = {
  BACKEND_URL: process.env.REACT_APP_API_BASE_URL,
  AUTH_URL: process.env.REACT_APP_KEYCLOAK_URL,
  PORTAL_URL: process.env.REACT_APP_PORTAL_URL,
  WS_HOST: process.env.REACT_APP_WS_HOST,
  MEDIA_HOST: process.env.REACT_APP_MEDIA_API_URL
};

const appFormats = {
  backendDateFormat: 'YYYY-MM-DD',
  frontDateFormat: 'DD/MM/YYYY',
  date: 'DD/MM/YYYY',
  dateTime: 'DD/MM/YYYY HH:mm:ss',
  frontDatetimeFormat: 'DD/MM/YYYY HH:mm:ss',
  number: '0,0.00'
};

const cacheEnabled = process.env.REACT_APP_CACHE_ENABLED === 'true';

const cachePages = {
  INVOICE_SERVICES: 'purchase-invoices',
  NCS_SERVICES: 'purchase-ncs-service',
  INVOICE_ITEMS: 'purchase-item-invoices'
};

const lineStatusVariants = [
  { value: 'O', label: 'Abierto', color: 'success' },
  { value: 'C', label: 'Cerrado', color: 'error' }
];

const Draft = 'D';
const Pending = 'P';
const Active = 'A';
const Paused = 'S';
const Cancelled = 'C';
const Closed = 'L';
const Archived = 'R';

const DraftName = 'Borrador';

const documentStatusViews = {
  VIEW_DRAFT: Draft,
  VIEW_PENDING: Pending,
  VIEW_ACTIVE: Active,
  VIEW_PAUSED: Paused,
  VIEW_CANCELLED: Cancelled,
  VIEW_CLOSED: Closed,
  VIEW_ARCHIVED: Archived
};

const documentStatusName = {
  VIEW_DRAFT_NAME: DraftName
};

const appTokens = {
  TOKEN_KEY_GOAL: `${appDefinitions.stage}_${process.env.REACT_APP_API_TOKEN_KEY_GOAL}`
};

const token = window.localStorage.getItem(appTokens.TOKEN_KEY_GOAL);

const pusherOptions = {
  cluster: 'mt0',
  activityTimeout: 100,
  encrypted: true,
  userAuthentication: {
    endpoint: `${appUrls.BACKEND_URL}/broadcasting/auth`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  },
  channelAuthorization: {
    endpoint: `${appUrls.BACKEND_URL}/broadcasting/channel-auth`,
    params: {
      param1: 'value1',
      param2: 'value2'
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  },

  wsHost: appUrls.WS_HOST,
  disableStats: true,
  httpHost: appUrls.WS_HOST,
  forceTLS: true
};

const statusVariantsDefault = [
  { color: 'success.main', label: 'Activo', value: ConstantStatus.ACTIVE },
  { color: 'warning.main', label: 'Inactivo', value: ConstantStatus.INACTIVE },
  { color: 'error.main', label: 'Eliminado', value: ConstantStatus.DELETED }
];

// default Dummy
const defaultDummy = {
  id: 0,
  name: 'Todos',
  code: 'ALL'
};

export {
  appDefinitions,
  cacheEnabled,
  cachePages,
  appFormats,
  lineStatusVariants,
  appUrls,
  documentStatusViews,
  documentStatusName,
  pusherOptions,
  statusVariantsDefault,
  defaultDummy
};
